import { createSlice } from '@reduxjs/toolkit';

import {
  tradingStatusOptions,
  listerStatusOptions,
  accountStatusOptions,
} from 'components/Admin/InfluencerServicesPage/Filters/config';
import { pagination } from 'models/pagination';
import { rating, status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';
import { thunks as listerInfoThunks } from './listerInfo/thunks';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    tradeStatus: tradingStatusOptions[0],
    listerStatus: listerStatusOptions[0],
    userStatus: accountStatusOptions[0],
    rating: Object.keys(rating),
    dateFrom: null,
    dateTo: null,
  },
  fetchingStatus: status.IDLE,
  updateAccountStatus: status.IDLE,
};

const slice = createSlice({
  name: 'listerServices',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getListers.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getListers.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getListers.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(listerInfoThunks.stopTrading.fulfilled, (state, { payload }) => {
        const { listerId, message } = payload;
        const itemIndex = state.list.findIndex((item) => item.id === listerId);
        if (itemIndex !== -1) {
          state.list[itemIndex].tradeStatus = message;
        }
      })

      .addCase(
        listerInfoThunks.startTrading.fulfilled,
        (state, { payload }) => {
          const { listerId, message } = payload;
          const itemIndex = state.list.findIndex(
            (item) => item.id === listerId
          );
          if (itemIndex !== -1) {
            state.list[itemIndex].tradeStatus = message;
          }
        }
      )

      .addCase(thunks.updateAccountStatus.pending, (state) => {
        state.updateAccountStatus = status.PENDING;
      })
      .addCase(thunks.updateAccountStatus.fulfilled, (state, { payload }) => {
        const { userId, message } = payload;
        const itemIndex = state.list.findIndex(
          (item) => item.userId === userId
        );
        state.list[itemIndex].accountStatus = message;
        state.updateAccountStatus = status.SUCCESS;
      })
      .addCase(thunks.updateAccountStatus.rejected, (state) => {
        state.updateAccountStatus = status.FAIL;
      });
  },
});

export const listerServices = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
