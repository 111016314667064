import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';
import { rating } from 'utils/const';

const tradingStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Paused', label: 'Paused' },
];

const listerStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Listed', label: 'Listed' },
  { value: 'Rejected', label: 'Rejected' },
];

const accountStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
  { value: 'suspended', label: 'Suspended' },
];

const ratingOptions = Object.entries(rating).map(([key, value]) => ({
  value: key,
  label: value,
}));

const schema = yup.object().shape({
  rating: yup.array().nullable(),
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

export {
  schema,
  tradingStatusOptions,
  listerStatusOptions,
  ratingOptions,
  accountStatusOptions,
};
